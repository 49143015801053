.InputContainer {
    display: flex;
    align-items: center;
    height: 100%;

  input {
    margin: 0 !important;
    height: 100% !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    border: none !important;
    outline: none !important;
  }

  input:focus, input:focus-visible {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    border-bottom: 1px solid #9e9e9e !important;
  }
}

.InputInvalid {
  border: 1px solid #d80606 !important;

  label {
    color: #d80606 !important;
  }
}

.SuggestionBox {
  position: absolute;
}
