.ButtonPrimary {
  background-color: #E57200 !important;
  color: #FFFFFF;
  box-shadow: none !important;
  border: 1px solid #E57200 !important;
  border-radius: 100px !important;
  text-transform: capitalize !important;
  padding: 0.5rem;
  cursor: pointer;
  font-family: "Noto Sans";

  &:disabled {
    background-color: #F5F5F5 !important;
    color: #79747E !important;
    border: 1px solid #79747E !important;
    cursor: not-allowed;
  }

  &:hover {
    background-color: #f49b45 !important;
  }

  &:focus, &:active {
    background-color: #FFFFFF !important;
    color: #E57200 !important;
  }
}

.ButtonPrimaryBlue {
  background-color: #12509F !important;
  color: #FFFFFF;
  box-shadow: none !important;
  border: 1px solid #12509F !important;
  text-transform: capitalize !important;
  padding: 0.5rem;
  cursor: pointer;
  font-family: "Noto Sans";

  &:disabled {
    background-color: #F5F5F5 !important;
    color: #79747E !important;
    border: 1px solid #79747E !important;
    cursor: not-allowed;
  }

  &:hover {
    background-color: #4b78b0 !important;
  }

  &:focus, &:active {
    background-color: #FFFFFF !important;
    color: #12509F !important;
  }
}

.ButtonLight {
  background-color: #FFFFFF;
  color: #79747E;
  box-shadow: none !important;
  border: 1px solid #79747E !important;
  border-radius: 100px !important;
  text-transform: capitalize !important;
  padding: 0.5rem;
  cursor: pointer;
  font-family: "Noto Sans";

  &:hover {
    background-color: #f1ebeb !important;
    color: #000000 !important;
  }

  &:focus, &:focus-visible, &:active {
    background-color: #79747E !important;
    color: #FFFFFF;
  }
}

.ButtonLightBlue {
  background-color: #FFFFFF !important;
  color: #12509F !important;
  box-shadow: none !important;
  border: 1px solid #12509F !important;
  text-transform: capitalize !important;
  padding: 0.5rem;
  cursor: pointer;
  font-family: "Noto Sans";

  &:hover {
    background-color: #f1ebeb !important;
    color: #12509F !important;
  }

  &:focus, &:focus-visible, &:active {
    background-color: #12509F !important;
    color: #FFFFFF !important;
  }
}