.Title {
  font-family: 'Noto Sans';
  font-size: 1.1rem;
  font-weight: 700;
}

.RegisteredMember {
  font-family: 'Noto Sans';

  img {
    height: 18px;
    width: 24px;
  }
}

.Subtitle {
  font-family: 'Noto Sans';
  font-style: italic;
}

.SuccessImage {
  width: 78px;
}

.Autocomplete {
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
