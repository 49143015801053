@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
.Spin::before {
  animation: 1.5s linear infinite spinner;
  animation-play-state: inherit;
  border: solid 10px #cfd0d1;
  border-bottom-color: #E57200;
  border-radius: 50%;
  content: "";
  height: 140px;
  width: 140px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  will-change: transform;
}

.Spin {
  img {
    position: absolute;
    top: calc(50vh - 22px);
    left: calc(50vw - 40px);
    width: 80px;
  }
}