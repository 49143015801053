/* INPUT CONTROL BOX */

.form-control-box {
  padding: 0.5rem;
  border-radius: 4px;
  height: 56px;
  margin-top: 1rem;
  margin-bottom: 1rem;

  border: 1px solid #79747E;
  transition: border 0.05s ease;

  .focus {
    border: 2px solid #79747E;
    transition: border 0.1s ease;

    label {
      font-weight: 600;
      transition: font-weight 0.1s ease;
    }
  }

  label {
    position: absolute;
    margin-top: -1.2rem;
    margin-left: 0.2rem;
    background-color: #FFFFFF;
    padding-right: 0.3rem;
    padding-left: 0.3rem;
    color: #1C1B1F;

    font-weight: normal;
    transition: font-weight 0.1s ease;
  }
}

/* INPUT CONTROL */

.input .input-container {
  display: flex;
  align-items: center;
  height: 100%;
}

.input .input-container input {
  margin: 0 !important;
  height: 100% !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  border-bottom: none !important;
}

.input .input-container input:focus {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  border-bottom: 1px solid #9e9e9e !important;
}

/* CHECKBOX */

.form-control-checkbox {
  display: flex;
  justify-content: flex-start;
}

.form-control-checkbox label {
  padding-left: 1rem;
  font-size: 1.2rem;
  font-family: "Noto Sans";
  cursor: pointer;
}

.form-control-checkbox input[type="checkbox"] {
  visibility: hidden;
}

.form-control-checkbox .checkmark-box {
  padding: 0 !important;
  width: 24px;
  min-width: 24px;
  height: 24px;
  min-height: 24px;
  background-color: #FFFFFF;
  border: 2px solid #E57200;
  border-radius: 3px;
  display: flex;
  justify-content: center;
}

.form-control-checkbox input[type="checkbox"]:checked ~ .checkmark-box  {
  background-color: #E57200;
}

.form-control-checkbox input[type="checkbox"]:checked ~ .checkmark-box .bi-check2::before  {
  color: #FFFFFF;
  font-weight: bold !important;
}

.form-control-checkbox input[type="checkbox"]:checked ~ .checkmark-box .bi-check2  {
  display: block;
}

.form-control-checkbox input[type="checkbox"] ~ .checkmark-box .bi-check2  {
  display: none;
}



